$(document).ready(function () {

  // Macro Wrapper (tags/buttons)
  var tagsWrapper = document.getElementsByClassName("tags__wrapper");
  var tag = $(".tag");

  tag.on("dragstart", function () {
    return false;
  });

  for (var i = 0; i < tagsWrapper.length; i++) {

    let _thisSlider = tagsWrapper[i];
    let isDown = false;
    let startX;
    let scrollLeft;

    // new SimpleBar(_thisSlider);

    _thisSlider.addEventListener('mousedown', (e) => {
      isDown = true;
      _thisSlider.classList.add('active');
      startX = e.pageX - _thisSlider.offsetLeft;
      scrollLeft = _thisSlider.scrollLeft;
    });

    _thisSlider.addEventListener('mouseleave', () => {
      isDown = false;
      _thisSlider.classList.remove('active');
      tag.removeClass("disableBtn");
    });

    _thisSlider.addEventListener('mouseup', () => {
      isDown = false;
      _thisSlider.classList.remove('active');
      tag.removeClass("disableBtn");
    });

    _thisSlider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - _thisSlider.offsetLeft;
      const walk = (x - startX) * 1; //set speed
      _thisSlider.scrollLeft = scrollLeft - walk;
      tag.addClass("disableBtn");
    });

  }
  // Macro Wrapper (tags/buttons)

  handleArrowTitles()

  handleHeaderFixed()

  handleHeaderMenuStatic()

  handleHeaderMenuFixed()

  if (!$(".layout-landing-page .page-header .tags").length) {
    $(".page-header").addClass("withoutTags");
  }

  // GLAVA-421
  // This code will force the picker to get the first option when the user went back to the page
  if ($('select.selectpicker').length) {
    const value = $("select.selectpicker option")[0].getAttribute('value')
    $('select.selectpicker').val(value)
    $('select.selectpicker').selectpicker('refresh')
  }
})

function handleArrowTitles () {
  // Add link arrow
  var heroTitle = $(".hero__content--article article a h2");
  addLinkArrow(heroTitle);

  // var boxTitle = $(".boxes a .box__content h3");
  // addLinkArrow(boxTitle);

  var articleListTitle = $(".listArticleWrapper__list--item a h2");
  addLinkArrow(articleListTitle);

  var lastPublishedsListTitle = $(".articleWrapper__lastPublisheds--item a h2");
  addLinkArrow(lastPublishedsListTitle);

  function addLinkArrow (el) {
    el.html(function () {
      var text = $(this).text().trim().split(" ");
      var last = text.pop();
      var lastWord = last.substring(0, (last.length - 1));
      var lastChar = last.split("");
      var char = lastChar.pop();
      return text.join(" ") + (last.length ? " " + lastWord + "<span>" + char + "</span>" : last);
    });
  }
  // Add link arrow
}

function handleHeaderFixed () {

  var lastScrollTop = 0,
    fixedMenu = $(".mainNav.fixed");
    jQuery(document).scroll(function () {
      var distanceTop = jQuery(document).scrollTop();
      let searchFixed = $("#autocompleteHeaderFixed");
      let searchbarFixedActive = $(".mainNav.fixed .searchbar-autocomplete").hasClass('searchbar-autocomplete--active')
    if (distanceTop > 150) {
      if (distanceTop < lastScrollTop) {
        // top
        fixedMenu.addClass("active");
      } else if (!searchbarFixedActive) {
          // down
        fixedMenu.removeClass("active");
        $("#autocompleteHeaderFixed").blur();
      }
      lastScrollTop = distanceTop;
    } else if (!searchbarFixedActive) {
      fixedMenu.removeClass("active");
      $("#autocompleteHeaderFixed").blur();
    }
  });

}

function handleHeaderMenuStatic () {

  var thisMenu = $('.mainNav.static');
  var mainMenu = thisMenu.find('header')
  var dropDownMainMenu = thisMenu.find('.dropDownMenu')
  var dropDownItems = thisMenu.find('.dropDownItems')
  var overlayPage = thisMenu.find('.overlayPage')
  var mainMenuItems = thisMenu.find('.main__menu--item.dropDown')
  var _thisItem
  var filterSoluctionWrapper = thisMenu.find(".filterSoluctionWrapper");

  mainMenuItems.click(function () {
    // mainMenu.addClass('dark')
    dropDownMainMenu.addClass('active')

    $('.header .sideBarMenu').removeClass('active')

    $(".bar").removeClass('animate');

    _thisItem = $(this).attr('data-item-menu') || ''

    if ($(this).hasClass('active') === true) {
      // mainMenu.removeClass('dark')
      dropDownMainMenu.removeClass('active')
      mainMenuItems.removeClass('active')
      $('body').removeClass('body-scroll-block');
    } else {
      disableItems()
      mainMenuItems.addClass('active')
      $('body').addClass('body-scroll-block');
      $('.dropDownItems[data-item-menu-target="' + _thisItem + '"]').addClass('active')
    }
  })

  function disableItems () {
    mainMenuItems.removeClass('active')
    dropDownItems.removeClass('active')
    $('.dropDownMenu[data-item-menu-target]').removeClass('active')
  }

  var burgerMenu = thisMenu.find('.main__menu--item.menu-hamburguer')
  var menuSideBar = $('.header .sideBarMenu')

  burgerMenu.click(function () {
    burgerMenu.find(".bar").toggleClass('animate');
    menuSideBar.toggleClass('active')
    mainMenu.toggleClass('active')
    thisMenu.find('.mainMenu__wrapper a, .dropDownMenu').removeClass('active')
    // mainMenu.removeClass('dark')
    filterSoluctionWrapper.toggleClass("show");

    if ($(this).hasClass('activated') === true && $('.main__menu--item.dropDown').hasClass('active') === true || $(this).hasClass('activated') === true && $('.main__menu--item.dropDown').hasClass('active') === false ) {
      $('body').addClass('body-scroll-block');
    } else if ($(this).hasClass('activated') === true){
      $(this).removeClass("activated")
      $('body').removeClass('body-scroll-block');
    } else{
      $(this).addClass("activated")
      $('body').addClass('body-scroll-block');
    }

    if ($('body').hasClass('body-scroll-block') === true && $('.header .sideBarMenu').hasClass('active') === true ) {
      $('body').addClass('body-scroll-block');
    } else{
      $('body').removeClass('body-scroll-block');
    }

  })

  overlayPage.click(function () {
    menuSideBar.removeClass('active')
    filterSoluctionWrapper.toggleClass("show");
    $(".bar").removeClass('animate');
    $('body').removeClass('body-scroll-block');
  })

  thisMenu.find(".dropDownMenu .overlayPage").mouseover(function () {
    // mainMenu.removeClass('dark')
    dropDownMainMenu.removeClass('active')
    mainMenuItems.removeClass('active')
    $('body').removeClass('body-scroll-block');
  })

  // Sidebar menu
  var dropDownSideMenu = thisMenu.find('.sideBarMenu__wrapper--content .items .dropDown:not(.notClick)')
  dropDownSideMenu.click(function () {
    $(this).find('.dropDownTitle').toggleClass('active')
    $(this).find('.dropDownItems').toggleClass("active")
  })
  // Sidebar menu


}

function handleHeaderMenuFixed () {

  var thisMenu = $('.mainNav.fixed');
  var mainMenu = thisMenu.find('header')
  var dropDownMainMenu = thisMenu.find('.dropDownMenu')
  var dropDownItems = thisMenu.find('.dropDownItems')
  var overlayPage = thisMenu.find('.overlayPage')
  var mainMenuItems = thisMenu.find('.main__menu--item.dropDown')
  var _thisItem
  var filterSoluctionWrapper = thisMenu.find(".filterSoluctionWrapper");

  mainMenuItems.click(function () {
    // mainMenu.addClass('dark')
    dropDownMainMenu.addClass('active')

    $('.header .sideBarMenu').removeClass('active')

    $(".bar").removeClass('animate');

    _thisItem = $(this).attr('data-item-menu') || ''

    if ($(this).hasClass('active') === true) {
      // mainMenu.removeClass('dark')
      dropDownMainMenu.removeClass('active')
      mainMenuItems.removeClass('active')
      $('body').removeClass('body-scroll-block');
    } else {
      disableItems()
      mainMenuItems.addClass('active')
      $('body').addClass('body-scroll-block');
      $('.dropDownItems[data-item-menu-target="' + _thisItem + '"]').addClass('active')
    }
  })

  function disableItems () {
    mainMenuItems.removeClass('active')
    dropDownItems.removeClass('active')
    $('.dropDownMenu[data-item-menu-target]').removeClass('active')
  }

  var burgerMenu = thisMenu.find('.main__menu--item.menu-hamburguer')
  var menuSideBar = $('.header .sideBarMenu')

  burgerMenu.click(function () {
    burgerMenu.find(".bar").toggleClass('animate');
    menuSideBar.toggleClass('active')
    thisMenu.find('.mainMenu__wrapper a, .dropDownMenu').removeClass('active')
    // mainMenu.removeClass('dark')
    filterSoluctionWrapper.toggleClass("show");

    if ($(this).hasClass('activated') === true && $('.main__menu--item.dropDown').hasClass('active') === true || $(this).hasClass('activated') === true && $('.main__menu--item.dropDown').hasClass('active') === false ) {
      $('body').addClass('body-scroll-block');
    } else if ($(this).hasClass('activated') === true){
      $(this).removeClass("activated")
      $('body').removeClass('body-scroll-block');
    } else{
      $(this).addClass("activated")
      $('body').addClass('body-scroll-block');
    }

    if ($('body').hasClass('body-scroll-block') === true && $('.header .sideBarMenu').hasClass('active') === true ) {
      $('body').addClass('body-scroll-block');
    } else{
      $('body').removeClass('body-scroll-block');
    }

  })

  overlayPage.click(function () {
    menuSideBar.removeClass('active')
    filterSoluctionWrapper.toggleClass("show");
    $(".bar").removeClass('animate');
    $('body').removeClass('body-scroll-block');
  })

  thisMenu.find(".dropDownMenu .overlayPage").mouseover(function () {
    mainMenu.removeClass('dark')
    dropDownMainMenu.removeClass('active')
    mainMenuItems.removeClass('active')
    $('body').removeClass('body-scroll-block');
  })

  // Sidebar menu
  var dropDownSideMenu = thisMenu.find('.sideBarMenu__wrapper--content .items .dropDown:not(.notClick)')
  dropDownSideMenu.click(function () {
    $(this).find('.dropDownTitle').toggleClass('active')
    $(this).find('.dropDownItems').toggleClass("active")
  })
  // Sidebar menu
}
